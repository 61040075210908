import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions, ChartEvent, ChartType } from "chart.js";
import { BaseChartDirective } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ILaps } from './model/pilote-details.model';
import sortingDataAccessor from '../shared/sortingDataAccessor';
import { global } from '../../environments/environment';
import Chart from 'chart.js/auto';
import 'hammerjs';
import zoomPlugin from 'chartjs-plugin-zoom';
import { isNullOrUndef } from 'chart.js/dist/helpers/helpers.core';
Chart.register(zoomPlugin);

@Component({
  selector: 'pilote-chart-details-table',
  templateUrl: './pilote-chart-details.component.html',
  styleUrls: ['./pilote-chart-details.component.scss'],
  providers: [DatePipe],
})
export class PiloteChartDetailsComponent implements OnInit {
  num: number;
  pilote: string;
  category: string;
  classement: number;
  ecart: string;
  diff: string;
  lap: number;
  bestlap: string;

  selectedPilotes: number[];

  allPilotes: any;

  legend: any = {
    'selectedPilote1': null,
    'selectedPilote2': null,
    'selectedPilote3': null,
    'selectedPilote4': null,
    'selectedPilote5': null,
    };

  colorList : any = [
    "#b8860b",
    "#483D8B",
    "#90EE90",
    "#FFB6C1",
    "#FF4500",
  ];

  dataset : any = {
    data: [ ],
    label: '',
    fill: false,
    tension: 0.5,
    borderColor: '#90EE90',
    backgroundColor: '#90EE90',
    pointBackgroundColor: '#90EE90',
    pointBorderColor: '#ffffff',
    spanGaps: true,
  };

  public lineChartDataClean: ChartConfiguration['data'] = {
    labels: [
    ],
    datasets: [
    ]
  };

  public lineChartData: ChartConfiguration['data'] = {
    labels: [
    ],
    datasets: [
    ]
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: true,
    hover: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      y: {
        position: 'left',
        ticks: {
          callback: ( value ) => {
            const seconds = value as number;
            var lapTimeString = "";
            var second = Math.floor(seconds % 60);
            if (seconds >= 3600) {
              var hour = Math.floor(seconds / 3600);
              var minutes = Math.floor((seconds - (hour * 3600)) / 60);
              var min = (minutes >= 10 ? "" : "0") + minutes;
              lapTimeString += hour + ":" + min + ":" + (second >= 10 ? "" : "0") + second;
            } else {
              var minutes = Math.floor(seconds / 60);
              var min = (minutes >= 10 ? "" : "0") + minutes;
              lapTimeString += min + ":" + (second >= 10 ? "" : "0") + second;
            }
            return lapTimeString;
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          filter: function(item, chart) {
            var text = item.text + "";
            return !text.includes("Moyenne");
          }
        },
        onClick: function(e, legendItem) {
          var index = legendItem.datasetIndex;
          var chart = this.chart;

          var pilot = chart.getDatasetMeta(index);
          pilot.hidden = pilot.hidden === null ? true : !pilot.hidden;

          chart.update();
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
        },
        pan: {
          enabled: true,
          mode: 'xy',
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks : {
          title(tooltipItems) {
            tooltipItems[0].label = "Tour " + tooltipItems[0].label;

            tooltipItems.forEach((currentValue, index) => {
              const seconds = Number(tooltipItems[index].formattedValue.replace(/\s/g, ""));
              var lapTimeString = "";
              var second = Math.floor(seconds % 60);
              if (seconds >= 3600) {
                var hour = Math.floor(seconds / 3600);
                var minutes = Math.floor((seconds - (hour * 3600)) / 60);
                var min = (minutes >= 10 ? "" : "0") + minutes;
                lapTimeString += hour + ":" + min + ":" + (second >= 10 ? "" : "0") + second;
              } else {
                var minutes = Math.floor(seconds / 60);
                var min = (minutes >= 10 ? "" : "0") + minutes;
                lapTimeString += min + ":" + (second >= 10 ? "" : "0") + second;
              }
              tooltipItems[index].formattedValue = lapTimeString;
            });
          },
        }
      }
    },
  };


  public dataSourceLaps = new MatTableDataSource<ILaps>();

   selectedPiloteschange = (event) => {
    if (this.selectedPilotes.length >= 1) {
      var num = '';
      var first = true;
      for (let i = 0; i < this.selectedPilotes.length; i++) {
        if (!first) {
          num += ",";
        }
        num += this.selectedPilotes[i];
        first = false;
      }
      this.loadContent(num);
    }
   }

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.dataSourceLaps.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        default: {
          return sortingDataAccessor.nestedCaseInsensitive(data, sortHeaderId);
        }
      }
    };
  }

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    // this needs to be a setter to ensure sort is added AFTER it is defined in the template, otherwise it won't work
    this.dataSourceLaps.sort = sort;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
        const num = +params.get('num');
        if (num) {
          this.http.get<any>('https://livetiming.guibert.co/api/livetiming/getChartDetails?name=' + global.dbname + '&num=' + num).subscribe(data => {
            // Pilote Details
            this.num = data.num;
            this.pilote = data.pilote;
            this.category = data.category;
            this.classement = data.classement;
            this.ecart = data.classement == 1 ? "-" : data.ecart;
            this.diff = data.classement == 1 ? "-" : data.diff;
            this.lap = data.lap;
            this.bestlap = data.bestlap;

            var ctx = this.chart?.chart.ctx;

            this.allPilotes = data.allPiloteNum;

            // Laps Chart Create
            // var indexPilot = 0;
            var numberOfPilot = data.lapChartList[0].lapTimeList.length;
            for (let indexPilot = 0; indexPilot < numberOfPilot; indexPilot++) {
              var object = null;
              object = Object.assign({}, this.dataset);
              // indexPilot = this.indexPilote(data, ++indexPilot);
              object.label = data.numPiloteList[indexPilot];
              object.data = [];
              object.borderColor = this.colorList[indexPilot];
              object.backgroundColor = this.colorList[indexPilot];
              object.pointBackgroundColor = this.colorList[indexPilot];
              
              this.lineChartData.datasets.push(object);
            }
            // Laps Chart
            for (let i = 0; i < data.lapChartList.length; i++) {
              this.lineChartData.labels.push(data.lapChartList[i].lap);
              for (let j = 0; j < numberOfPilot; j++) {
                this.lineChartData.datasets[j].data.push(data.lapChartList[i].lapTimeList[j]);
              }
            }
            this.chart?.update();

          })

          global.num = num;
        }
       })
  }

  loadContent(multipleNum) : void {
    const num = multipleNum;
    this.http.get<any>('https://livetiming.guibert.co/api/livetiming/getChartDetails?name=' + global.dbname + '&num=' + num).subscribe(data => {

      this.lineChartData.datasets = [];
      this.lineChartData.labels = [];

      // Pilote Details
      this.num = data.num;
      this.pilote = data.pilote;
      this.category = data.category;
      this.classement = data.classement;
      this.ecart = data.classement == 1 ? "-" : data.ecart;
      this.diff = data.classement == 1 ? "-" : data.diff;
      this.lap = data.lap;
      this.bestlap = data.bestlap;

      var ctx = this.chart?.chart.ctx;

      this.allPilotes = data.allPiloteNum;

      // Laps Chart Create
      var numberOfPilot = data.lapChartList[0].lapTimeList.length;
      for (let indexPilot = 0; indexPilot < numberOfPilot; indexPilot++) {
        var object = null;
        object = Object.assign({}, this.dataset);
        object.label = data.numPiloteList[indexPilot];
        object.data = [];
        object.borderColor = this.colorList[indexPilot];
        object.backgroundColor = this.colorList[indexPilot];
        object.pointBackgroundColor = this.colorList[indexPilot];
        
        this.lineChartData.datasets.push(object);
      }
      // Laps Chart
      for (let i = 0; i < data.lapChartList.length; i++) {
        this.lineChartData.labels.push(data.lapChartList[i].lap);
        for (let j = 0; j < numberOfPilot; j++) {
          this.lineChartData.datasets[j].data.push(data.lapChartList[i].lapTimeList[j]);
        }
      }
      this.chart?.update();

    })

    global.selectedPilotesGlobal = num;
  }

}
