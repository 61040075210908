<div class="pilote-chart-details-table-component-container">
  
  <aside class="top-bar">
    <div class="home-stats">
      <div class="home-stat">
        <h1 class="home-text-black"><span>{{ this.num }}</span></h1>
        <span class="home-text-header">Numéro</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-pilote"><span>{{ this.pilote }}</span></h1>
        <span class="home-text-header">Pilote</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-black"><span>{{ this.category }}</span></h1>
        <span class="home-text-header">Catégorie</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text"><span>{{ this.classement }}</span></h1>
        <span class="home-text-header">Classement</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text"><span>{{ this.ecart }}</span></h1>
        <span class="home-text-header">Ecart avec le premier</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text"><span>{{ this.diff }}</span></h1>
        <span class="home-text-header">Difference avec le prochain</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-black"><span>{{ this.lap }}</span></h1>
        <span class="home-text-header">Tour actuel</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-green"><span>{{ this.bestlap }}</span></h1>
        <span class="home-text-header">Meilleur Tour</span>
      </div>
    </div>
  </aside>
  
  <div class="pilote-laps-table-container">

   <div class="pilote-laps-chart-div">
      <canvas
        baseChart
        class="pilote-laps-chart"
        [data]="lineChartData"
        [options]="lineChartOptions"
        [type]="'line'">
      </canvas>
    </div>

    <div class="pilotes-chart-select">

      <ng-select class="custom"
        [items]="this.allPilotes"
        (close)="selectedPiloteschange($event)"
        bindLabel="num" 
        bindValue="num"
        [multiple]="true"
        [maxSelectedItems]="5"
        [(ngModel)]="this.selectedPilotes">
      </ng-select>
      <!-- <button type="submit">click</button> -->

      <div class="pilote-laps-chart-legend-div">
        <div class="home-stat">
          <span class="legend-text-1 legend-h2">{{this.legend.selectedPilote1}}</span>
        </div>
        <div class="home-stat">
          <span class="legend-text-2 legend-h2">{{this.legend.selectedPilote2}}</span>
        </div>
        <div class="home-stat">
          <span class="legend-text-3 legend-h2">{{this.legend.selectedPilote3}}</span>
        </div>
        <div class="home-stat">
          <span class="legend-text-4 legend-h2">{{this.legend.selectedPilote4}}</span>
        </div>
        <div class="home-stat">
          <span class="legend-text-5 legend-h2">{{this.legend.selectedPilote5}}</span>
        </div>
      </div>
    </div>

  </div>

</div>
