import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { ILastLapAll } from './model/last-lap-all.model';
import sortingDataAccessor from '../shared/sortingDataAccessor';
import { global } from '../../environments/environment';

@Component({
  selector: 'last-lap-all-table',
  templateUrl: './last-lap-all.component.html',
  styleUrls: ['./last-lap-all.component.scss'],
})
export class LastLapAllComponent implements OnInit {
  public readonly displayedColumns = [
    'num',
    'pilote',
    'category',
    'classement',
    'ecart',
    'diff',
    'lap',
    'lastlap',
  ];

  public dataSource = new MatTableDataSource<ILastLapAll>();

  constructor(private http: HttpClient) {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        // case 'name': {
        //   return sortingDataAccessor.caseInsensitive(data, sortHeaderId);
        // }
        // case 'address.streetName': {
        //   return sortingDataAccessor.nestedProperty(data, sortHeaderId);
        // }
        default: {
          return sortingDataAccessor.nestedCaseInsensitive(data, sortHeaderId);
        }
      }
    };
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    // this needs to be a setter to ensure sort is added AFTER it is defined in the template, otherwise it won't work
    this.dataSource.sort = sort;
  }

  ngOnInit(): void {
    this.http.get<any>('https://livetiming.guibert.co/api/livetiming/getAllLast?name=' + global.dbname).subscribe(data => {
          this.dataSource.data = data;
          }) 
  }

  reloadPage() {
    setTimeout(()=>{
      window.location.reload();
    }, 100);
  }

}
