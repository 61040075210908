<div class="pilote-details-table-component-container">
  
  <aside class="top-bar">
    <div class="home-stats">
      <div class="home-stat">
        <h1 class="home-text-black"><span>{{ this.num }}</span></h1>
        <span class="home-text-header">Numéro</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-pilote"><span>{{ this.pilote }}</span></h1>
        <span class="home-text-header">Pilote</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-black"><span>{{ this.category }}</span></h1>
        <span class="home-text-header">Catégorie</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text"><span>{{ this.classement }}</span></h1>
        <span class="home-text-header">Classement</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text"><span>{{ this.ecart }}</span></h1>
        <span class="home-text-header">Ecart avec le premier</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text"><span>{{ this.diff }}</span></h1>
        <span class="home-text-header">Difference avec le prochain</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-black"><span>{{ this.lap }}</span></h1>
        <span class="home-text-header">Tour actuel</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-green"><span>{{ this.bestlap }}</span></h1>
        <span class="home-text-header">Meilleur Tour</span>
      </div>
    </div>
  </aside>
  
  <div class="pilote-laps-table-container">

    <div class="pilote-laps-table-div">
      <table
        class="pilote-laps-table"
        mat-table
        [dataSource]="dataSourceLaps"
        matSort
        matSortActive="lap"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="lap">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tour</th>
          <td mat-cell *matCellDef="let row" class="number">
            {{ row.lap }}
          </td>
        </ng-container>

        <ng-container matColumnDef="laptime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="laptime">Temps</th>
          <td mat-cell *matCellDef="let row" class="text">
            {{ row.laptime }}
          </td>
        </ng-container>

        <tr class="mat-header-row-laps" mat-header-row *matHeaderRowDef="displayedColumnsLaps; sticky: true"></tr>
        <tr class="mat-row-laps" mat-row *matRowDef="let row; columns: displayedColumnsLaps" [class.pilote-laps-pit]='row.pit'></tr>
      </table>
    </div>

    <div class="pilote-laps-chart-div">
      <canvas
        baseChart
        class="pilote-laps-chart"
        [data]="lineChartData"
        [options]="lineChartOptions"
        [type]="'line'">
      </canvas>
    </div>

    <div class="pilote-laps-chart-legend-div">
      <div class="home-stat">
        <span class="legend-text-1 legend-h2"><span>{{ this.num }}</span></span>
        <span class="legend-text-header-1">Pilote</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-2 legend-h2" [routerLink]="['/PiloteDetailsGeneral/', this.legend.minus1Num]" (click)="reloadPage()"><span>{{ this.legend.minus1Num }}</span></span>
        <span class="legend-text-header">{{ this.legend.minus1 }}</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-3 legend-h2" [routerLink]="['/PiloteDetailsGeneral/', this.legend.minus2Num]" (click)="reloadPage()"><span>{{ this.legend.minus2Num }}</span></span>
        <span class="legend-text-header">{{ this.legend.minus2 }}</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-4 legend-h2" [routerLink]="['/PiloteDetailsGeneral/', this.legend.plus1Num]" (click)="reloadPage()"><span>{{ this.legend.plus1Num }}</span></span>
        <span class="legend-text-header">{{ this.legend.plus1 }}</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-5 legend-h2" [routerLink]="['/PiloteDetailsGeneral/', this.legend.plus2Num]" (click)="reloadPage()"><span>{{ this.legend.plus2Num }}</span></span>
        <span class="legend-text-header">{{ this.legend.plus2 }}</span>
      </div>
    </div>
    <div class="pilote-laps-overtake-div">
      <div class="home-stat">
        <span class="legend-text legend-h2"><span>-</span></span>
        <span class="legend-text-overtake">-</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-green legend-h2"><span>{{ this.legend.minus1OvertakeTime }}</span></span>
        <span class="legend-text-overtake">{{ this.legend.minus1Overtake }}</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-green legend-h2"><span>{{ this.legend.minus2OvertakeTime }}</span></span>
        <span class="legend-text-overtake">{{ this.legend.minus2Overtake }}</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-red legend-h2"><span>{{ this.legend.plus1OvertakeTime }}</span></span>
        <span class="legend-text-overtake">{{ this.legend.plus1Overtake }}</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-red legend-h2"><span>{{ this.legend.plus2OvertakeTime }}</span></span>
        <span class="legend-text-overtake">{{ this.legend.plus2Overtake }}</span>
      </div>
    </div>
    <div class="pilote-laps-moyenne-div">
      <div class="home-stat">
        <span class="legend-text-black legend-h2"><span>{{ this.legend.moyenne }}</span></span>
        <span class="legend-text-moyenne">Moyenne</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-black legend-h2"><span>{{ this.legend.minus1Moyenne }}</span></span>
        <span class="legend-text-moyenne">{{ this.legend.minus1MoyenneString }}</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-black legend-h2"><span>{{ this.legend.minus2Moyenne }}</span></span>
        <span class="legend-text-moyenne">{{ this.legend.minus2MoyenneString }}</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-black legend-h2"><span>{{ this.legend.plus1Moyenne }}</span></span>
        <span class="legend-text-moyenne">{{ this.legend.plus1MoyenneString }}</span>
      </div>
      <div class="home-stat">
        <span class="legend-text-black legend-h2"><span>{{ this.legend.plus2Moyenne }}</span></span>
        <span class="legend-text-moyenne">{{ this.legend.plus2MoyenneString }}</span>
      </div>
    </div>

  </div>
</div>
