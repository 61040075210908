<div class="last-lap-all-table-component-container">
  <!-- <aside class="top-bar">
    <h3 id="pageTitle" class="page-title">User Table</h3>
  </aside> -->
  <div class="last-lap-all-table-container">
    <mat-table #table
      class="last-lap-all-table"
      [dataSource]="dataSource"
      matSort
      matSortActive="classement"
      matSortDirection="asc"
    >
      <ng-container matColumnDef="num">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Numéro</th>
        <td mat-cell *matCellDef="let row" class="number">
          {{ row.num }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pilote">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="pilote">Pilotes</th>
        <td mat-cell *matCellDef="let row" class="text">
          {{ row.pilote }}
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="category">Catégorie</th>
        <td mat-cell *matCellDef="let row" class="text">
          {{ row.category }}
        </td>
      </ng-container>

      <ng-container matColumnDef="classement">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="classement">Classement</th>
        <td mat-cell *matCellDef="let row" class="text">
          {{ row.classement}}
        </td>
      </ng-container>

      <ng-container matColumnDef="ecart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="ecart">Ecart avec le premier</th>
        <td mat-cell *matCellDef="let row" class="text">
          {{ row.ecart }}
        </td>
      </ng-container>

      <ng-container matColumnDef="diff">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="diff">Difference avec le suivant</th>
        <td mat-cell *matCellDef="let row" class="text">
          {{ row.diff }}
        </td>
      </ng-container>
 
      <ng-container matColumnDef="lap">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lap">Tour</th>
        <td mat-cell *matCellDef="let row" class="text">
          {{ row.lap }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastlap">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lastlap">Dernier Tour</th>
        <td mat-cell *matCellDef="let row" class="text">
          {{ row.lastlap }}
        </td>
      </ng-container>

      <tr class="mat-header-row" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr class="mat-row" mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="['/PiloteDetails/', row.num]" (click)="reloadPage()"></tr>
    </mat-table>
  </div>
</div>
