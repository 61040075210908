import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions, ChartEvent, ChartType } from "chart.js";
import { BaseChartDirective } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { ILaps } from './model/pilote-details.model';
import sortingDataAccessor from '../shared/sortingDataAccessor';
import { global } from '../../environments/environment';
import Chart from 'chart.js/auto';
import 'hammerjs';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin);

@Component({
  selector: 'pilote-details-table',
  templateUrl: './pilote-details.component.html',
  styleUrls: ['./pilote-details.component.scss'],
  providers: [DatePipe],
})
export class PiloteDetailsComponent implements OnInit {
  num: number;
  pilote: string;
  category: string;
  classement: number;
  ecart: string;
  diff: string;
  lap: number;
  bestlap: string;

  // type = ChartType.LineChart;
  data = [];

  public lineChartData: ChartConfiguration['data'] = {
    labels: [
    ],
    datasets: [
      {
        data: [ ],
        label: '',
        fill: false,
        tension: 0.5,
        borderColor: '#b8860b',
        backgroundColor: '#b8860b',
        pointBackgroundColor: '#b8860b',
        pointBorderColor: '#ffffff',
      },
      {
        data: [ ],
        label: 'Moyenne',
        fill: false,
        tension: 0.5,
        borderColor: '#8b0000',
        backgroundColor: '#8b0000',
        pointBackgroundColor: '#8b0000',
        pointBorderColor: '#ffffff',
        pointRadius: 0,
        borderDash: [10,2],
      }
    ]
  };



  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      y: {
        position: 'left',
        // scaleLabel: {
        //   display: true,
        //   labelString: 'Temps'
        // },
        ticks: {
          callback: ( value ) => {
            const seconds = value as number;
            var lapTimeString = "";
            var second = Math.floor(seconds % 60);
            if (seconds >= 3600) {
              var hour = Math.floor(seconds / 3600);
              var minutes = Math.floor((seconds - (hour * 3600)) / 60);
              var min = (minutes >= 10 ? "" : "0") + minutes;
              lapTimeString += hour + ":" + min + ":" + (second >= 10 ? "" : "0") + second;
            } else {
              var minutes = Math.floor(seconds / 60);
              var min = (minutes >= 10 ? "" : "0") + minutes;
              lapTimeString += min + ":" + (second >= 10 ? "" : "0") + second;
            }
            return lapTimeString;
          },
        },
      },
    },
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
        },
        pan: {
          enabled: true,
          mode: 'xy',
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks : {
          title(tooltipItems) {
            tooltipItems[0].label = "Tour " + tooltipItems[0].label;

            tooltipItems.forEach((currentValue, index) => {
              const seconds = Number(tooltipItems[index].formattedValue.replace(/\s/g, ""));
              var lapTimeString = "";
              var second = Math.floor(seconds % 60);
              if (seconds >= 3600) {
                var hour = Math.floor(seconds / 3600);
                var minutes = Math.floor((seconds - (hour * 3600)) / 60);
                var min = (minutes >= 10 ? "" : "0") + minutes;
                lapTimeString += hour + ":" + min + ":" + (second >= 10 ? "" : "0") + second;
              } else {
                var minutes = Math.floor(seconds / 60);
                var min = (minutes >= 10 ? "" : "0") + minutes;
                lapTimeString += min + ":" + (second >= 10 ? "" : "0") + second;
              }
              tooltipItems[index].formattedValue = lapTimeString;
            });
          },
        }
      }
    },
  };

  public readonly displayedColumnsLaps = [
    'lap',
    'laptime',
  ];

  public dataSourceLaps = new MatTableDataSource<ILaps>();

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.dataSourceLaps.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        default: {
          return sortingDataAccessor.nestedCaseInsensitive(data, sortHeaderId);
        }
      }
    };
  }

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    // this needs to be a setter to ensure sort is added AFTER it is defined in the template, otherwise it won't work
    this.dataSourceLaps.sort = sort;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
        const num = +params.get('num');
        if (num) {
          this.http.get<any>('https://livetiming.guibert.co/api/livetiming/getPiloteDetails?name=' + global.dbname + '&num=' + num).subscribe(data => {
            // Pilote Details
            this.num = data.num;
            this.pilote = data.pilote;
            this.category = data.category;
            this.classement = data.classement;
            this.ecart = data.classement == 1 ? "-" : data.ecart;
            this.diff = data.classement == 1 ? "-" : data.diff;
            this.lap = data.lap;
            this.bestlap = data.bestlap;

            // All Pilote Laps
            this.dataSourceLaps.data = data.lapsList.lapList;

            // Laps Chart
            for (let i = 0; i < data.lapsList.lapChartList.length; i++) {
              var lap = [data.lapsList.lapChartList[i].lap, data.lapsList.lapChartList[i].laptime, data.lapsList.lapChartList[i].laptimewithoutstand];
              this.data.push(lap);
            }

            // Laps Chart ng2
            this.lineChartData.datasets[0].label = data.num;
            for (let i = data.lapsList.lapChartList.length - 1; i >= 0; i--) {
              this.lineChartData.labels.push(data.lapsList.lapChartList[i].lap);
              this.lineChartData.datasets[0].data.push(data.lapsList.lapChartList[i].laptime);
              this.lineChartData.datasets[1].data.push(data.lapsList.lapChartList[i].laptimewithoutstand);
            }
            this.chart?.update();

          })

          global.num = num;
        }
       })

  }

}
