import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgChartsModule } from 'ng2-charts';
import { NgSelectModule} from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component'
import { LastLapAllComponent } from './last-lap-all/last-lap-all.component';
import { PiloteDetailsComponent } from './pilote-details/pilote-details.component';
import { PiloteDetailsGeneralComponent } from './pilote-details-general/pilote-details-general.component';
import { PiloteDetailsCategoryComponent } from './pilote-details-category/pilote-details-category.component';
import { PiloteChartDetailsComponent } from './pilote-chart-details/pilote-chart-details.component';
import 'hammerjs';
import 'chartjs-plugin-zoom';


@NgModule({
  declarations: [AppComponent, MenuComponent, LastLapAllComponent, PiloteDetailsComponent, PiloteDetailsGeneralComponent, PiloteDetailsCategoryComponent, PiloteChartDetailsComponent],
  imports: [BrowserModule, NoopAnimationsModule, MatTableModule, MatSortModule, MatTabsModule, AppRoutingModule, MatToolbarModule, MatButtonModule, HttpClientModule, GoogleChartsModule, NgChartsModule, NgSelectModule, FormsModule],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule {}
