import { Component, OnInit } from '@angular/core';
import { global } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit() {
  }

  tolink(url: string) {
    var result = url + global.num;
    console.log(result);
    this.router.navigate([result]);
    setTimeout(()=>{
      window.location.reload();
    }, 100);
  }

}