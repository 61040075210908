import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartOptions, ChartEvent, ChartType } from "chart.js";
import { BaseChartDirective } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { ILaps } from './model/pilote-details.model';
import sortingDataAccessor from '../shared/sortingDataAccessor';
import { global } from '../../environments/environment';
import Chart from 'chart.js/auto';
import 'hammerjs';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin);

@Component({
  selector: 'pilote-details-table',
  templateUrl: './pilote-details-category.component.html',
  styleUrls: ['./pilote-details-category.component.scss'],
  providers: [DatePipe],
})
export class PiloteDetailsCategoryComponent implements OnInit {
  num: number;
  pilote: string;
  category: string;
  classement: number;
  ecart: string;
  diff: string;
  lap: number;
  bestlap: string;

  colorList : any = [
    "#b8860b",
    "#483D8B",
    "#90EE90",
    "#FFB6C1",
    "#FF4500",
  ];

  dataset : any = {
    data: [ ],
    label: '',
    fill: false,
    tension: 0.5,
    borderColor: '#90EE90',
    backgroundColor: '#90EE90',
    pointBackgroundColor: 'rgba(148,159,177,1)',
    pointBorderColor: '#ffffff',
  };

  datasetWithDash : any = {
    data: [ ],
    label: '',
    fill: false,
    tension: 0.1,
    borderColor: '#90EE90',
    backgroundColor: '#90EE90',
    pointBackgroundColor: 'rgba(148,159,177,1)',
    pointBorderColor: '#ffffff',
    pointRadius: 0,
    borderDash: [10,2],
    borderWidth: 1.5,
  };


   public lineChartData: ChartConfiguration['data'] = {
    labels: [
    ],
    datasets: [
    ]
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: true,
    hover: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      y: {
        position: 'left',
        ticks: {
          callback: ( value ) => {
            const seconds = value as number;
            var lapTimeString = "";
            var second = Math.floor(seconds % 60);
            if (seconds >= 3600) {
              var hour = Math.floor(seconds / 3600);
              var minutes = Math.floor((seconds - (hour * 3600)) / 60);
              var min = (minutes >= 10 ? "" : "0") + minutes;
              lapTimeString += hour + ":" + min + ":" + (second >= 10 ? "" : "0") + second;
            } else {
              var minutes = Math.floor(seconds / 60);
              var min = (minutes >= 10 ? "" : "0") + minutes;
              lapTimeString += min + ":" + (second >= 10 ? "" : "0") + second;
            }
            return lapTimeString;
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          filter: function(item, chart) {
            var text = item.text + "";
            return !text.includes("Moyenne");
          }
        },
        onClick: function(e, legendItem) {
          var index = legendItem.datasetIndex;
          var chart = this.chart;

          var pilot = chart.getDatasetMeta(index);
          var moyenne = chart.getDatasetMeta(index+1);
          pilot.hidden = pilot.hidden === null ? true : !pilot.hidden;
          moyenne.hidden = pilot.hidden;

          chart.update();
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
        },
        pan: {
          enabled: true,
          mode: 'xy',
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks : {
          title(tooltipItems) {
            tooltipItems[0].label = "Tour " + tooltipItems[0].label;

            tooltipItems.forEach((currentValue, index) => {
              const seconds = Number(tooltipItems[index].formattedValue.replace(/\s/g, ""));
              var lapTimeString = "";
              var second = Math.floor(seconds % 60);
              if (seconds >= 3600) {
                var hour = Math.floor(seconds / 3600);
                var minutes = Math.floor((seconds - (hour * 3600)) / 60);
                var min = (minutes >= 10 ? "" : "0") + minutes;
                lapTimeString += hour + ":" + min + ":" + (second >= 10 ? "" : "0") + second;
              } else {
                var minutes = Math.floor(seconds / 60);
                var min = (minutes >= 10 ? "" : "0") + minutes;
                lapTimeString += min + ":" + (second >= 10 ? "" : "0") + second;
              }
              tooltipItems[index].formattedValue = lapTimeString;
            });
          },
        }
      }
    },
  };

   
   legend: any = {
    'moyenne': null,

    'minus1': null,
    'minus1Num': null,
    'minus1Overtake': null,
    'minus1Moyenne': null,

    'minus2': null,
    'minus2Num': null,
    'minus2Overtake': null,
    'minus2Moyenne': null,

    'plus1': null,
    'plus1Num': null,
    'plus1Overtake': null,
    'plus1Moyenne': null,

    'plus2': null,
    'plus2Num': null,
    'plus2Overtake': null,
    'plus2Moyenne': null
    };



  public readonly displayedColumnsLaps = [
    'lap',
    'laptime',
  ];

  public dataSourceLaps = new MatTableDataSource<ILaps>();

  indexPilote = (data, indexPilot) => {
   for (let j = 0; j < data.lapsList.otherPilotsDetails.length; j++) {
      if (j < indexPilot) {
        continue;
      }
      if (data.lapsList.otherPilotsDetails[j].num != 0) {
        return j;
      }
   }
   return 0;
  }

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.dataSourceLaps.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        default: {
          return sortingDataAccessor.nestedCaseInsensitive(data, sortHeaderId);
        }
      }
    };
  }

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    // this needs to be a setter to ensure sort is added AFTER it is defined in the template, otherwise it won't work
    this.dataSourceLaps.sort = sort;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
        const num = +params.get('num');
        if (num) {
          this.http.get<any>('https://livetiming.guibert.co/api/livetiming/getPiloteDetailsCategory?name=' + global.dbname + '&num=' + num).subscribe(data => {
            // Pilote Details
            this.num = data.num;
            this.pilote = data.pilote;
            this.category = data.category;
            this.classement = data.lapsList.otherPilotsDetails[0].classement;
            this.ecart = data.classement == 1 ? "-" : data.ecart;
            this.diff = data.classement == 1 ? "-" : data.diff;
            this.lap = data.lap;
            this.bestlap = data.bestlap;

            // All Pilote Laps
            this.dataSourceLaps.data = data.lapsList.lapList;

            // Laps Chart Create
            var indexPilot = -1;
            var numberOfPilot = data.lapsList.lapChartList[0].lapTimeList.length;
            for (let i = 0; i < numberOfPilot; i++) {
              var object = null;
              if (i % 2 == 0) {
                object = Object.assign({}, this.dataset);
                indexPilot = this.indexPilote(data, ++indexPilot);
                object.label = data.lapsList.otherPilotsDetails[indexPilot].num;
              } else {
                object = Object.assign({}, this.datasetWithDash);
                object.label = "Moyenne";
              }
              object.data = [];
              object.borderColor = this.colorList[indexPilot];
              object.backgroundColor = this.colorList[indexPilot];
              object.pointBackgroundColor = this.colorList[indexPilot];
              
              this.lineChartData.datasets.push(object);
            }
            // Laps Chart
            for (let i = 0; i < data.lapsList.lapChartList.length; i++) {
              this.lineChartData.labels.push(data.lapsList.lapChartList[i].lap);
              for (let j = 0; j < numberOfPilot; j++) {
                this.lineChartData.datasets[j].data.push(data.lapsList.lapChartList[i].lapTimeList[j]);
              }
            }
            this.chart?.update();

            // Legend
            let firstBefore = true;
            let firstAfter = true;
            for (let i = 0; i < data.lapsList.otherPilotsDetails.length; i++) {
              var classement = data.lapsList.otherPilotsDetails[i].classement - data.lapsList.otherPilotsDetails[0].classement;
              this.legend.moyenne = data.lapsList.otherPilotsDetails[0].moyenne;

              if (data.lapsList.otherPilotsDetails[i].classement < data.lapsList.otherPilotsDetails[0].classement
                  && data.lapsList.otherPilotsDetails[i].classement != 0) {
                if (firstBefore) {
                  this.legend.minus1Num = data.lapsList.otherPilotsDetails[i].num;
                  this.legend.minus1 = data.lapsList.otherPilotsDetails[i].classement != null ? "Classement " + data.lapsList.otherPilotsDetails[i].classement : null;
                  this.legend.minus1Overtake = data.lapsList.otherPilotsDetails[i].overtake;
                  this.legend.minus1Moyenne = data.lapsList.otherPilotsDetails[i].moyenne;
                  firstBefore = false;
                } else {
                  this.legend.minus2Num = data.lapsList.otherPilotsDetails[i].num;
                  this.legend.minus2 = data.lapsList.otherPilotsDetails[i].classement != null ? "Classement " + data.lapsList.otherPilotsDetails[i].classement : null;
                  this.legend.minus2Overtake = data.lapsList.otherPilotsDetails[i].overtake;
                  this.legend.minus2Moyenne = data.lapsList.otherPilotsDetails[i].moyenne;
                }
              } else if (data.lapsList.otherPilotsDetails[i].classement > data.lapsList.otherPilotsDetails[0].classement
                        && data.lapsList.otherPilotsDetails[i].classement != 0) {
                if (firstAfter) {
                  this.legend.plus1Num = data.lapsList.otherPilotsDetails[i].num;
                  this.legend.plus1 = data.lapsList.otherPilotsDetails[i].classement != null ? "Classement " + data.lapsList.otherPilotsDetails[i].classement : null;
                  this.legend.plus1Overtake = data.lapsList.otherPilotsDetails[i].overtake;
                  this.legend.plus1Moyenne = data.lapsList.otherPilotsDetails[i].moyenne;
                  firstAfter = false;
                } else {
                  this.legend.plus2Num = data.lapsList.otherPilotsDetails[i].num;
                  this.legend.plus2 = data.lapsList.otherPilotsDetails[i].classement != null ? "Classement " + data.lapsList.otherPilotsDetails[i].classement : null;
                  this.legend.plus2Overtake = data.lapsList.otherPilotsDetails[i].overtake;
                  this.legend.plus2Moyenne = data.lapsList.otherPilotsDetails[i].moyenne;
                }
              }
            }

          })

          global.num = num;
        }
       })

  }

  reloadPage() {
    setTimeout(()=>{
      window.location.reload();
    }, 100);
  }

}