<div class="pilote-details-table-component-container">
  
  <aside class="top-bar">
    <div class="home-stats">
      <div class="home-stat">
        <h1 class="home-text-black"><span>{{ this.num }}</span></h1>
        <span class="home-text-header">Numéro</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-pilote"><span>{{ this.pilote }}</span></h1>
        <span class="home-text-header">Pilote</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-black"><span>{{ this.category }}</span></h1>
        <span class="home-text-header">Catégorie</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text"><span>{{ this.classement }}</span></h1>
        <span class="home-text-header">Classement</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text"><span>{{ this.ecart }}</span></h1>
        <span class="home-text-header">Ecart avec le premier</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text"><span>{{ this.diff }}</span></h1>
        <span class="home-text-header">Difference avec le prochain</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-black"><span>{{ this.lap }}</span></h1>
        <span class="home-text-header">Tour actuel</span>
      </div>
      <div class="home-stat">
        <h1 class="home-text-green"><span>{{ this.bestlap }}</span></h1>
        <span class="home-text-header">Meilleur Tour</span>
      </div>
    </div>
  </aside>
  
  <div class="pilote-laps-table-container">

    <div class="pilote-laps-table-div">
      <table
        class="pilote-laps-table"
        mat-table
        [dataSource]="dataSourceLaps"
        matSort
        matSortActive="lap"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="lap">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tour</th>
          <td mat-cell *matCellDef="let row" class="number">
            {{ row.lap }}
          </td>
        </ng-container>

        <ng-container matColumnDef="laptime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="laptime">Temps</th>
          <td mat-cell *matCellDef="let row" class="text">
            {{ row.laptime }}
          </td>
        </ng-container>

        <tr class="mat-header-row-laps" mat-header-row *matHeaderRowDef="displayedColumnsLaps; sticky: true"></tr>
        <tr class="mat-row-laps" mat-row *matRowDef="let row; columns: displayedColumnsLaps" [class.pilote-laps-pit]='row.pit'></tr>
      </table>
    </div>

    <div class="pilote-laps-chart-div">
      <canvas
        baseChart
        class="pilote-laps-chart"
        [data]="lineChartData"
        [options]="lineChartOptions"
        [type]="'line'"
      ></canvas>
    </div>

    <!-- <div class="pilote-laps-chart-div">
      <google-chart #chart
        class="pilote-laps-chart"
        [type]="type"
        [data]="data"
        [columnNames]="columnNames"
        [options]="options">
      </google-chart>
    </div> -->

  </div>
</div>
