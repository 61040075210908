import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LastLapAllComponent } from './last-lap-all/last-lap-all.component';
import { PiloteDetailsComponent } from './pilote-details/pilote-details.component';
import { PiloteDetailsGeneralComponent } from './pilote-details-general/pilote-details-general.component';
import { PiloteDetailsCategoryComponent } from './pilote-details-category/pilote-details-category.component';
import { PiloteChartDetailsComponent } from './pilote-chart-details/pilote-chart-details.component';

export const appRouteList: Routes = [
    {
        path: 'LastLapAll',
        component: LastLapAllComponent
    },
    {
        path: 'PiloteDetails/:num',
        component: PiloteDetailsComponent
    },
    {
        path: 'PiloteDetailsGeneral/:num',
        component: PiloteDetailsGeneralComponent
    },
    {
        path: 'PiloteDetailsCategory/:num',
        component: PiloteDetailsCategoryComponent
    },
    {
        path: 'PiloteChartDetails/:num',
        component: PiloteChartDetailsComponent
    },
    {
        path: '**',
        redirectTo: 'LastLapAll'
    }
];

@NgModule({
    exports: [
        RouterModule
    ],
    imports: [
        RouterModule.forRoot(appRouteList)
    ]
})
export class AppRoutingModule {
}